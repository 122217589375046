const { parse } = JSON;

interface IAuth {
  clear(key: string): void;
  clearAppStorage(): void;
  get(key: string): JSON;
  setToken(token: string): void;
  getToken(): any;
  setType(type: string): void;
  getType(): any;
  setRefreshToken(token: string): void;
  getRefreshToken(): any;
}

const auth: IAuth = {
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    return null;
  },

  clearAppStorage() {
    return localStorage.clear();
  },

  get(key) {
    return parse(localStorage.getItem(key) as string);
  },

  setToken(token) {
    return localStorage.setItem('token', token);
  },

  getToken() {
    return localStorage.getItem('token');
  },

  setType(type) {
    return localStorage.setItem('type', type);
  },

  getType() {
    return localStorage.getItem('type');
  },

  setRefreshToken(token) {
    return localStorage.setItem('refreshToken', token);
  },

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  },
};

export default auth;
