import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {
  AppCurriculum,
  AppCurriculumPage,
  AppChatting,
  AppDaily,
  AppDailyMission,
  AuthFindAccount,
  AuthFindPassword,
  AuthLogin,
  AuthNewPassword,
  AuthRegister,
  Root,
  SorituneMessage,
  SorituneIntro,
  SorituneLearning,
  ClassTuningProgram,
  ClassCoaching,
  ClassActuality,
  Program,
  AppMindset,
  AppMindsetInfo,
  AppHelpdesk,
  AppEvent,
  AppEventInfo,
  AppSetting,
  AppNotice,
  AppNoticeDetail,
  AppMypage,
  AppQuestion,
  AppQuestionInfo,
  AppInquiry,
  AppReservation,
  ProgramDetail,
  CoachLogin,
  Redirect,
  CoachingDetail,
} from '../pages';
import useSWR, { SWRConfig } from 'swr';
import { api } from '../utils/api';
import PrivateRoute from '../components/PrivateRoute';
import auth from '../utils/auth';

const App: React.FC = () => {
  const swrConfig = {
    fetcher(url: string, params: object) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  const { data: myData } = useSWR(() => '/mypage');

  useEffect(() => {
    var w: any = window;

    (function () {
      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function () {})(
          'ChannelIO script included twice.',
        );
      }
      var ch: any = function () {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function (args: any) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        var x: any = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('DOMContentLoaded', l, false);
        w.addEventListener('load', l, false);
      }
    })();

    if (auth.getToken()) {
      if (myData) {
        console.log();
        w.ChannelIO('boot', {
          pluginKey: '833d7321-7f71-4097-9043-6f6397366fda',
          memberId: myData?.user?.email,
          profile: {
            name: myData?.user?.name,
            mobileNumber: myData?.user?.phone,
          },
        });
        w.ChannelIO('hideChannelButton');
      }
    } else {
      w.ChannelIO('boot', {
        pluginKey: '833d7321-7f71-4097-9043-6f6397366fda',
      });
    }
  }, [myData]);

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/redirect" component={Redirect} />
          <Route exact path="/" component={Root} />

          <Route exact path="/soritune/message" component={SorituneMessage} />
          <Route exact path="/soritune/intro" component={SorituneIntro} />
          <Route exact path="/soritune/learning" component={SorituneLearning} />

          <Route exact path="/class/program" component={ClassTuningProgram} />
          <Route exact path="/class/coaching" component={ClassCoaching} />
          <Route exact path="/class/actuality" component={ClassActuality} />

          <Route exact path="/programDetail" component={ProgramDetail} />
          <Route exact path="/coachingDetail" component={CoachingDetail} />
          <Route exact path="/auth/login" component={AuthLogin} />
          <Route exact path="/auth/register" component={AuthRegister} />
          <Route exact path="/auth/find-account" component={AuthFindAccount} />
          <Route exact path="/coach/login" component={CoachLogin} />
          <Route
            exact
            path="/auth/find-password"
            component={AuthFindPassword}
          />
          <Route exact path="/auth/new-password" component={AuthNewPassword} />
          <PrivateRoute exact path="/app/daily" component={AppDaily} />
          <PrivateRoute
            exact
            path="/app/daily/mission"
            component={AppDailyMission}
          />
          <PrivateRoute exact path="/app/chatting" component={AppChatting} />

          <PrivateRoute
            exact
            path="/app/curriculum"
            component={AppCurriculum}
          />

          <PrivateRoute
            exact
            path="/app/curriculum/:curriculumId/:mainCurriculumId"
            // path="/app/curriculum/:curriculumId/:mainCurriculumId/:subCurriculumId/:curriculum"
            component={AppCurriculumPage}
          />
          <PrivateRoute exact path="/app/mindset" component={AppMindset} />
          <PrivateRoute
            exact
            path="/app/mindset/:mindsetId"
            component={AppMindsetInfo}
          />
          <PrivateRoute
            exact
            path="/app/setting/helpdesk"
            component={AppHelpdesk}
          />
          <PrivateRoute exact path="/app/setting/event" component={AppEvent} />
          <PrivateRoute
            exact
            path="/app/setting/event/:eventId"
            component={AppEventInfo}
          />
          <PrivateRoute exact path="/app/setting" component={AppSetting} />
          <PrivateRoute exact path="/app/mypage" component={AppMypage} />
          <PrivateRoute
            exact
            path="/app/setting/helpdesk/qna"
            component={AppQuestion}
          />
          <PrivateRoute
            exact
            path="/app/setting/helpdesk/inquiry"
            component={AppInquiry}
          />
          <PrivateRoute
            exact
            path="/app/setting/helpdesk/qna/:questionId"
            component={AppQuestionInfo}
          />
          <PrivateRoute
            exact
            path="/app/setting/notice"
            component={AppNotice}
          />
          <PrivateRoute
            exact
            path="/app/setting/notice/:noticeId"
            component={AppNoticeDetail}
          />
          <PrivateRoute
            exact
            path="/app/setting/notice/:noticeId"
            component={AppEventInfo}
          />
          <PrivateRoute
            exact
            path="/app/reservation"
            component={AppReservation}
          />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
