import Axios from 'axios';
import auth from './auth';

export const endpoint = 'https://dev.api.soritunenglish.co.kr/api';
// export const endpoint = 'https://api.soritunenglish.co.kr/api';

export const api = Axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url ===
        'https://dev.api.soritunenglish.co.kr/api/refresh/token'
      // 'https://api.soritunenglish.co.kr/api/refresh/token'
    ) {
      auth.clear('token');

      // window.location.href = '/auth/error';
      return Promise.reject(error);
    }

    if (localStorage.getItem('retry')) {
      localStorage.removeItem('retry');
      // window.location.href = '/auth/error';
      return null;
    }

    if (error.response.status === 401 && !localStorage.getItem('retry')) {
      localStorage.setItem('retry', 'true');
      const refreshToken = auth.getRefreshToken();
      return api
        .post('/refresh/token', {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              auth.setToken(res.data.data.access_token);
              auth.setRefreshToken(res.data.data.refresh_token);
              api.defaults.headers['Authorization'] =
                res.data.data.access_token;
              return window.location.reload();
            } else {
              auth.clear('token');
              return null;
            }
          } else {
            auth.clear('token');
            return null;
          }
        })
        .catch((err) => console.log(err));
    }

    return Promise.reject(error);
  },
);
